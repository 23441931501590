import { useContext, useEffect } from "react";
import Button from "./Button";
import DataListItem from "./DataListItem";
import { BPCCContext } from "../state/BPCCContext";

const DataList = ({ setTimestamp }) => {
  const { data, getData, setCurrentAudioFile } = useContext(BPCCContext);
  const {
    audioDetails,
    audioFileList,
    loading,
    currentAudioDetails,
    filteredAudioDetails,
  } = data;

  useEffect(() => {
    if (Object.keys(audioDetails).length === 0) {
      getData();
    }
  }, []);

  const setCurrentAudio = (audioUrl, audioId, audioDetail) => {
    setCurrentAudioFile(audioUrl, audioId, audioDetail);
    setTimestamp(0.0);
  };

  return (
    <div className="rounded bg-white p-4 ">
      <div className="max-h-96 mb-2 rounded overflow-x-scroll">
        <table className="w-full rounded text-left shadow-md border-spacing-x-2 border-spacing-y-2">
          <thead className="sticky top-0 bg-slate-200">
            <tr className="">
              <th className="font-medium text-sm">Source Text</th>
              <th className="font-medium text-sm">Target Text</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(filteredAudioDetails).map((audioId, index) => {
              const audioDetail = filteredAudioDetails[audioId];
              return (
                <DataListItem
                  key={index}
                  index={index}
                  info={audioDetail}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <Button onClick={getData} label={"Load more"} loading={loading} />
    </div>
  );
};

export default DataList;
