import React from "react";
import Button from "./Button";

const DownloadButtons = ({ editCurrentPage }) => {
  return (
    <div className="flex flex-col md:flex-row lg:flex-col items-center gap-3 lg:border rounded-b px-4 py-4">
      <a href="https://arxiv.org/abs/2305.16307" target="_blank">
        <Button label={"Read more on arXiv"} onClick={() => { }} />
      </a>
      <a href="https://ai4bharat.iitm.ac.in/blog/indictrans2-m2m/" target="_blank">
        <Button label={"Read our blog"} onClick={() => { }} />
      </a>
      <Button
        label={"Explore BPCC"}
        onClick={() => editCurrentPage("explore")}
      />
      <Button
        label={"Download BPCC"}
        onClick={() => editCurrentPage("download")}
      />
    </div>
  );
};

export default DownloadButtons;
