import { useContext } from "react";
import { BPCCContext } from "../state/BPCCContext";
import LogoutButton from "./LogoutButton";

const Header = () => {
  const { editCurrentPage, logout, data } = useContext(BPCCContext);
  return (
    <div className="flex bg-orange-300 justify-between rounded-b px-4 py-4">
      <button onClick={() => editCurrentPage("home")}>
        <h2 className="text-2xl font-semibold">Bharat Parallel Corpus Collection (BPCC)</h2>
      </button>
      <div className="flex gap-2">
        {data.currentPage !== "home" ? (
          <button
            className="md:px-1 md:shadow-lg rounded md:ring-1 ring-black"
            onClick={() => editCurrentPage("home")}
          >
            <h2 className="text-sm font-medium">Back</h2>
          </button>
        ) : (
          ""
        )}

        {data.currentPage !== "explore" ? (
          <button
            className="md:px-1 md:shadow-lg rounded md:ring-1 ring-black"
            onClick={() => editCurrentPage("explore")}
          >
            <h2 className="text-sm font-medium">Explore</h2>
          </button>
        ) : (
          ""
        )}

        {data.currentPage !== "download" ? (
          <button
            className="md:px-1 md:shadow-lg rounded md:ring-1 ring-black"
            onClick={() => editCurrentPage("download")}
          >
            <h2 className="text-sm font-medium">Download</h2>
          </button>
        ) : (
          ""
        )}
        {data.currentPage === "download" && data.authInfo.jwt ? (
          <LogoutButton logout={logout} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
