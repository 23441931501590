import { useContext, useEffect, useState } from "react";
import { BPCCContext } from "../state/BPCCContext";
import SubHeading from "./SubHeading";

const indian_languages_flores = {
    'asm_Beng': 'Assamese',
    'ben_Beng': 'Bengali',
    'brx_Deva': 'Bodo',
    'doi_Deva': 'Dogri',
    'guj_Gujr': 'Gujarati',
    'hin_Deva': 'Hindi',
    'kan_Knda': 'Kannada',
    'kas_Arab': 'Kashmiri',
    'kok_Deva': 'Konkani',
    'mai_Deva': 'Maithili',
    'mal_Mlym': 'Malayalam',
    'mni_Beng': 'Manipuri',
    'mar_Deva': 'Marathi',
    'npi_Deva': 'Nepali',
    'ory_Orya': 'Odia',
    'pan_Guru': 'Punjabi',
    'san_Deva': 'Sanskrit',
    'sat_Olck': 'Santali',
    'snd_Arab': 'Sindhi',
    'tam_Taml': 'Tamil',
    'tel_Telu': 'Telugu',
    'urd_Arab': 'Urdu',
    'eng_Latn': 'English'
}

const DownloadListItem = ({file,token})=>{
  const createUrl = (fileName,token) => {
    return `https://bpcc.ai4bharat.org/api/download_dataset/${fileName}?token=${token}`;
  };
  const languages = file.fileName.replace(".tar.gz","")
  const languageInfo = languages.split("-")
  return <div className="bg-slate-100 p-2 min-w-fit">
      <span className="text-sm font-bold">
        Source Language: <span>{indian_languages_flores[languageInfo[0]]}</span><br/>
        Target Language: <span>{indian_languages_flores[languageInfo[1]]}</span><br/>
        Filename: <span>{file.fileName}</span>
      </span>
      <div className="flex flex-row rounded">
        <a
                  href={createUrl(file.fileName,token)}
                  className="text-xs text-blue-400 underline "
                >
                  Download
                </a>
        </div>
        </div>
}

const DownloadList = () => {
  const { data, getDownloadList } = useContext(BPCCContext);
  const { downloadList, authInfo } = data;

  useEffect(() => {
    getDownloadList();
  }, []);

  return (
    <div className="bg-white p-4">
      <SubHeading heading={"Downloads"} />
      <span className="text-sm">
        Please click{" "}
        <a
          className="underline text-blue-400"
          href={`https://bpcc.ai4bharat.org/api/download_dataset/bpcc_human_july_2024_release.tar.gz?token=${authInfo.jwt}`}
        >
          here
        </a>{" "}
        to download the latest release of BPCC Dataset.
      </span>
      <div className="mt-4 overflow-x-scroll no-scrollbar">
        {downloadList.map((file,index)=>{
          return <><DownloadListItem file={file} token={authInfo.jwt}/><br/></>
        })}
        {/* {Object.keys(downloadList).map((key) => {
          return (
            <DownlaodListItem
              key={key}
              token={authInfo.jwt}
              version={key}
              infos={downloadList[key]}
            />
          );
        })} */}
      </div>
      <p className="text-xs font-semibold mt-4">
        Note: If you encounter issues with the download, kindly log out and then
        log back in.
      </p>
    </div>
  );
};

export default DownloadList;
