import { useContext, useState } from "react";
import Input from "../components/Inputs";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import { BPCCContext } from "../state/BPCCContext";
import SubHeading from "./SubHeading";

const Register = () => {
  const { data, editAuthInfo, sendRegistrationOtp } =
    useContext(BPCCContext);
  const { name, organization, phone, email, otp, loading } = data.authInfo;
  const { registerError } = data.errors;

  const setValue = (key, value) => {
    editAuthInfo({ [key]: value });
  };

  return (
    <div className="p-4 bg-white rounded">
      <SubHeading heading={"Register"} />
      <Input
        heading={"Enter you full name"}
        id="name"
        state={data}
        validateField={() => { }}
        component={
          <TextInput
            value={name}
            placeholder={"Mohan Kumar"}
            id="name"
            type="text"
            setValue={setValue}
          />
        }
      />
      <Input
        heading={"Enter you organization's name"}
        id="organization"
        state={data}
        validateField={() => { }}
        component={
          <TextInput
            value={organization}
            placeholder={"AI4Bharat"}
            id="organization"
            type="text"
            setValue={setValue}
          />
        }
      />
      <Input
        heading={"Enter you email"}
        id="email"
        state={data}
        validateField={() => { }}
        hint={"The OTP will be sent to this email"}
        component={
          <TextInput
            value={email}
            placeholder={"mohankumar@mail.com"}
            id="email"
            type="text"
            setValue={setValue}
          />
        }
      />
      <Input
        heading={"Enter you phone number"}
        id="phone"
        state={data}
        notRequired={true}
        validateField={() => { }}
        component={
          <TextInput
            value={phone}
            placeholder={"8071798925"}
            id="phone"
            type="text"
            setValue={setValue}
          />
        }
      />
      <Button
        label={"Send OTP"}
        onClick={() => {
          sendRegistrationOtp();
        }}
        loading={loading}
      />
      {registerError ? (
        <p className="text-sm text-red-400">{registerError}</p>
      ) : (
        ""
      )}
    </div>
  );
};

const Login = () => {
  const { data, editLoginInfo, checkIfUserExist } =
    useContext(BPCCContext);
  const { loginEmail, loading } = data.loginInfo;
  const { loginError } = data.errors;

  const setValue = (key, value) => {
    editLoginInfo({ [key]: value });
  };

  return (
    <div className="p-4 bg-white rounded">
      <SubHeading heading={"Login"} />
      <Input
        heading={"Enter the registered email"}
        id="loginEmail"
        state={data}
        validateField={() => { }}
        component={
          <TextInput
            value={loginEmail}
            placeholder={"test@mail.com"}
            id="loginEmail"
            type="text"
            setValue={setValue}
          />
        }
      />
      <Button
        label={"Login"}
        onClick={() => checkIfUserExist(loginEmail)}
        loading={loading}
      />
      {loginError ? <p className="text-sm text-red-400">{loginError}</p> : ""}
    </div>
  );
};

const Verify = () => {
  const { data, editAuthInfo, completeRegistration } =
    useContext(BPCCContext);
  const { email, otp } = data.authInfo;
  const { verifyOtpError } = data.errors;

  const setValue = (key, value) => {
    editAuthInfo({ [key]: value });
  };

  return (
    <div className="flex flex-col p-4 bg-white rounded">
      <SubHeading heading={"Register"} />
      <div className="flex flex-col justify-arund flex-1">
        <Input
          heading={"Enter OTP"}
          id="otp"
          state={data}
          validateField={() => { }}
          component={
            <TextInput
              value={otp}
              placeholder={123456}
              id="otp"
              type="text"
              setValue={setValue}
            />
          }
        />
        <Button
          label={"Register"}
          onClick={completeRegistration}
          loading={data.loading}
        />
        {verifyOtpError ? (
          <p className="text-sm text-red-400">{verifyOtpError}</p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const Auth = () => {
  const { data } = useContext(BPCCContext);
  const { otpSendStatus } = data.authInfo;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
      {otpSendStatus ? <Verify /> : <Register />}
      <Login />
    </div>
  );
};

export default Auth;
